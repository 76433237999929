import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/mdx-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1><a parentName="h1" {...{
        "href": "/career/justo"
      }}>{`Justo (2020 - Present)`}</a></h1>
    <p><strong parentName="p">{`Role: Principal Software Engineer.`}</strong></p>
    <p>{`Justo is a software company that has the goal of digitalizing businesses in Latin America (mainly in the food industry) by giving them their own e-commerce platform and handling the logistics.`}</p>
    <h1><a parentName="h1" {...{
        "href": "/career/fleek"
      }}>{`Fleek (2018 - 2020)`}</a></h1>
    <p><strong parentName="p">{`Role: Cofounder & Tech Lead`}</strong></p>
    <p>{`Fleek is a New York based software company that builds products and dev-tools for the crypto industry. Its main product is a hosting service that stores assets in IPFS, serves them through a CDN, and allows configuring decentralized domains such as ENS or HNS. It also handles the CI/CD pipeline by integrating with GitHub.`}</p>
    <h1><a parentName="h1" {...{
        "href": "/career/papinotas"
      }}>{`Papinotas (2016 - 2018)`}</a></h1>
    <p><strong parentName="p">{`Role: CTO`}</strong></p>
    <p>{`Papinotas is a chilean software company that offers technology to schools. Its initial and most used product allows schools to handle communication with the student parents. It also offers management tools and attendance control for schools.`}</p>
    <h1><a parentName="h1" {...{
        "href": "/career/tiendapp"
      }}>{`TiendApp (2015 - 2020)`}</a></h1>
    <p><strong parentName="p">{`Role: Founder & CEO`}</strong></p>
    <p>{`My own startup that was bootstraped with two friends from college. The product was an e-commerce platform for small to medium businesses, similar to Shopify but focused on the chilean ecosystem.`}</p>
    <h1><a parentName="h1" {...{
        "href": "/career/earlier-work"
      }}>{`Earlier work (2011-2015)`}</a></h1>
    <p>{`I got my Major in Computer Science and Professional Degree in Software Engineering from 2011 to 2016. Although I finally formalized my computer knowledge by going to college, I learned the most by working in software companies in parallel. I worked at two different places in that period.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      